import React from "react";
import "./hdh-app.css";

export default function HDHApp(): React.ReactElement {
  return (
    <div className="hero">
        <div className="photo"></div>
        <main className="message">
            <div className="title">
                HDH Immobilien GmbH
            </div>
            <div className="subtitle">
                Die Webseite befindet sich aktuell im Aufbau.
            </div>
        </main>
        <div className="attribution">
            Photo by{' '}
            <a href="https://unsplash.com/photos/3coKbdfnAFg">
                Henry & Co.
            </a>
            {' '}on{' '}
            <a href="https://unsplash.com/">
                Unsplash
            </a>
        </div>
    </div>
  );
}
