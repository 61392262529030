import React from "react";
import ReactDOM from "react-dom";
import HDHApp from "./hdh-app/hdh-app";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { registerServiceWorker } from "./service-worker-registration";

ReactDOM.render(
  <React.StrictMode>
    <HDHApp />
  </React.StrictMode>,
  document.getElementById("root")
);

registerServiceWorker();
reportWebVitals();
